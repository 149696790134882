import React, { useContext, useState } from 'react'
import t from '@jetshop/intl'
import { SiteContext } from '../../../Global/SiteContext'
import { styled } from 'linaria/react'
import { cx, css } from 'linaria'
import { theme } from '../../../Theme'

const VatLabel = styled.label`
   color: #fff;
   font-family: var(--font-industry);
   ${theme.below.md} {
      font-size: 14px;
   }
`

const Input = styled.input`
   margin-bottom: 3px;

   &.form-check-input[type='checkbox'] {
      /* appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none; */
      /* border-radius: 50% !important; */
   }
   &.form-check-input {
      /* appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none; */
      border-radius: 50% !important;
   }

   &.form-check-input:checked {
      background-color: white;
      border-color: white;
      /* border-radius: 50% !important; */
   }

   &.form-check-input:checked[type='checkbox'] {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23195178'/%3E%3C/svg%3E");
      background-size: 1.5rem;
      /* border-radius: 50% !important; */
   }
`

const VatSelector = () => {
   const { showVat, handleShowVatChange } = useContext(SiteContext)
   const [toggle, setToggle] = useState(false)

   const handleChange = val => {
      setToggle(val)
   }
   return (
      <div className="d-flex flex-wrap gap-2">
         <div className="form-check pr-8 d-flex align-items-center gap-2">
            <Input
               className="form-check-input"
               type="checkbox"
               name="flexRadioDefault"
               id="flexRadioDefault1"
               checked={showVat}
               onChange={() => handleShowVatChange(true)}
            />
            <VatLabel
               className="form-check-label link link--white link--vb"
               htmlFor="flexRadioDefault1"
            >
               {' '}
               {t('Privat')}
            </VatLabel>
         </div>
         <div className="form-check pl-32 d-flex align-items-center gap-2">
            <Input
               className="form-check-input"
               type="checkbox"
               name="flexRadioDefault"
               id="flexRadioDefault2"
               checked={!showVat}
               onChange={() => handleShowVatChange(false)}
            />
            <VatLabel
               className="form-check-label link link--white link--vb"
               htmlFor="flexRadioDefault2"
            >
               {' '}
               {t('Företag')}
            </VatLabel>
         </div>
      </div>
   )
}

export default VatSelector
