export const ACCORDION = 'Accordion'
export const CTA_CARDS = 'CTA Cards'
export const EMBED_YOUTUBE = 'Embed Youtube'
export const HERO_SECTION = 'Hero section'
export const IMAGE_CARD_OVERLAY = 'Image Card With Overlay'
export const IMAGE_COLUMN = 'Image Column'
export const LINK_CARDS_CAROUSEL = 'Link Cards Carousel'
export const READ_MORE = 'readMore'
export const SITE_SETTING = 'Site Setting'
export const TOP_BAR_SETTING = 'Top Bar'
export const Product_Page_Settings = 'Product Page Settings'
export const SPACING = 'Spacing'
export const TEXT = 'Text'
export const TEXT_COLUMN = 'Text Column'
export const FAQ_ACCORDION = 'FAQ Accordion'
export const HELLO_RETAIL_RECOMMENDATION = 'Hello Retail Recommendation'
export const MANUAL_RECOMMENDATION = 'Manual Recommendation'
export const HELLO_RETAIL_SETTINGS = 'Hello Retail Settings'
export const VB_ONE_COLUMN = 'VB - One Column'
export const VB_TWO_COLUMN = 'VB - Two Column'
export const VB_THREE_COLUMN = 'VB - Three Column'
export const VB_FOUR_COLUMN = 'VB - Four Column'
export const VB_BLURB = 'VB - Blurb'
export const VB_STEPS = 'VB - DIY Steps'
export const VB_DESKTOP_MENU = 'VB - Desktop Menu'
export const VB_DESKTOP_MENU_CATEGORY = 'vb - menuCategory'
export const VB_MENU_FEATURED_CATEGORY = 'vb - highlightCategory'
export const VB_MOBILE_MENU = 'VB - Mobile Menu'
export const VB_MOBILE_MENU_CATEGORY  = 'vb - menuCategoryMobile'
export const VB_CATEGORY_SETTINGS = 'VB - Category Settings'
export const VB_FAQ_ACCORDION = 'VB - FAQ Accordion'
export const VB_FOOTER = 'VB - Footer'
export const VB_FOOTER_INSPIRATION = 'vb - footerInspiration'
export const VB_FOOTER_NEWSLETTER = 'vb - footerNewsletter'
export const VB_FOOTER_CONTACT = 'vb - footerContact'
export const VB_FOOTER_ICONS = 'vb - footerIcons'
export const VB_FOOTER_BODY_COLUMN = 'vb - footerBodyColumn'
export const VB_FOOTER_BODY_ICON = 'vb - footerBodyIcon'
export const VB_VIDEO_BANNER = 'VB - Video Banner'
export const VB_BRAND_LIST = 'VB - Brandlist'
export const VB_KUNDO = 'VB - Kundo'
export const VB_CONTENT_WIDGET = 'VB - Content Widget'
export const VB_DRAWER_MENU = 'VB - Drawer Menu'
export const VB_WARNING_BANNER = 'VB - Warning Banner'
export const VB_BUBBLE_WIDGET = 'VB - Bubble Widget'
export const VB_PICTURE_BANNER = 'VB - Picture Banner'
export const VB_MANUAL_BLOGPOSTS = 'VB - Manual Blogposts'
export const VB_SIDE_MENU_LINK = 'vb - sideMenuLink'
export const USP = 'usp'