import React from 'react'
import {styled} from 'linaria/react'
import FooterInspiration from './FooterInspiration';
import FooterIcons from './FooterIcons';
import FooterBottomBar from './FooterBottomBar';
import FooterBody from './FooterBody';
import FooterContact from './FooterContact';
import { VB_FOOTER, VB_FOOTER_BODY_COLUMN, VB_FOOTER_BODY_ICON, VB_FOOTER_CONTACT, VB_FOOTER_ICONS, VB_FOOTER_INSPIRATION, VB_FOOTER_NEWSLETTER } from '../../CustomElements/elementTypes';
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings';
import { getStringByPropertyName } from '../../Core/Util/Helpers';

const FooterWrapper = styled.footer`
  color: #fff;

  h2 {
    font-size: 24px;
  }

  .logo-image {
    height: 28px; 
    max-width: 96px;
  }
`

const Footer = () => {
  const [getSetting, loading] = useSiteSettingsQuery();
  const inspirationBlocks = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type == VB_FOOTER_INSPIRATION);
  const newsLetter = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type == VB_FOOTER_NEWSLETTER);
  const contactBlocks = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type == VB_FOOTER_CONTACT);
  const iconBlocks = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type == VB_FOOTER_ICONS);
  const bodyColumnBlocks = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type ==   VB_FOOTER_BODY_COLUMN);
  const bodyColumnIcons = getSetting(null, VB_FOOTER)?.children?.filter(c => c.type ==   VB_FOOTER_BODY_ICON);
  const bottomContent = getStringByPropertyName(
    getSetting(null, VB_FOOTER)?.properties,
    'bottomContent'
  );
  
  return(
    <FooterWrapper className="container-fluid m-0 p-0">
      <div className="row p-0 m-0">
        <FooterInspiration newsLetter={newsLetter?.length > 0 ? newsLetter[0] : null} inspirationBlocks={inspirationBlocks}></FooterInspiration>
        <FooterContact contactBlocks={contactBlocks} />
      </div>
      <div className="row p-0 m-0">
        <FooterIcons iconBlocks={iconBlocks}></FooterIcons>
        <FooterBody bodyColumnBlocks={bodyColumnBlocks} bodyColumnIcons={bodyColumnIcons}></FooterBody>
        <FooterBottomBar bottomContent={bottomContent}></FooterBottomBar>
      </div> 
  </FooterWrapper>
  )
}

export default Footer
