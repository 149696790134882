import {trackCartCheckoutEvent} from '@jetshop/core/analytics/tracking'
import Swal from 'sweetalert2';

const NavigateToCheckout = (cartData, track) => {
    track(trackCartCheckoutEvent({ cart: cartData }))
    window.location.href = cartData?.externalCheckoutUrl
}

export const ShippingAlert = (cartData, alertIds, track, selectedChannel) => {

   if (selectedChannel?.id != 1) {
      NavigateToCheckout(cartData, track);
      return
   }

   //Loop through cart data and filter items
   let showAlert = false
   if(alertIds){
    const ids = alertIds.split(',').map(String)
    for (let cartItem of cartData?.items) {
       if (ids.includes(cartItem?.articleNumber)) {
          showAlert = true
          break
       }
    }
   }

   if (!showAlert) {
      NavigateToCheckout(cartData, track);
      return
   }

   Swal.fire({
      title: 'Stämmer dina uppgifter?',
      text: 'Tänk på att välja leveransadress om varan ska till annan adress än faktura- eller bokföringsadressen. Det gör du i Steg 4 i kassan.',
      icon: 'info',
      confirmButtonText: 'OK',
      customClass: {
         confirmButton: 'vb-confirm-dialog-button',
      },
   }).then(value => {
      // Code to execute when the user clicks "OK"
      if (value?.isConfirmed) {
        NavigateToCheckout(cartData, track);
      }
   })
}