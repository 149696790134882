import React, { useState, useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import SiteButton from '../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import { useIntl } from '@jetshop/intl'
import { useHistory } from "react-router-dom";

const NewsletterBlock = styled.div`
    text-align: center;
    background: var(--color-vb-blue);

    .footer-newsletter-input {
        border-radius: 50px;
        max-width: 20rem;
    }
`

const FooterNewsletter = ({ newsLetter }) => {
   const [email, setEmail] = useState("");
   const title = getStringByPropertyName(newsLetter?.properties, 'title')
   const subTitle = getStringByPropertyName(newsLetter?.properties, 'subTitle')
   const link = getStringByPropertyName(newsLetter?.properties, 'link')
   const t = useIntl();
   const history = useHistory();

   const handleInputKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            history.push(`${link}?email=${email}`);
        }
   }

   return (
      <NewsletterBlock className="col-12 col-lg-6 py-64 px-32 d-flex flex-column justify-content-center align-items-center">
         <h2 className="m-0 text-uppercase">{title}</h2>
         <p className="my-2 mb-3">{subTitle}</p>
         <div className='w-100 d-flex justify-content-center gap-2'>
            <input
                type="text"
                className="flex-grow-1 flex-md-grow-0 form-control footer-newsletter-input w-50 m-0 py-2 px-4"
                placeholder={t('provide your email address')}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleInputKeyDown}
            />
            <SiteButton href={`${link}?email=${email}`} className="py-8 px-16" type="submit" styleType="hollow" color="white" icon={true}>
                {t('Not yet a member? Sign Up')}
                <NavArrowRight strokeWidth={2.0} style={{marginLeft: '0.3rem'}}></NavArrowRight>
            </SiteButton>
        </div>
      </NewsletterBlock>
   )
}
 
export default FooterNewsletter;