export const metaPixelSw = {
   metaPixelScript: `
   !function(f,b,e,v,n,t,s)
   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
   n.queue=[];t=b.createElement(e);t.async=!0;
   t.src=v;s=b.getElementsByTagName(e)[0];
   s.parentNode.insertBefore(t,s)}(window, document,'script',
   'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '495888791243962');
   fbq('track', 'PageView')`,
   noScriptImgPixelElement:`
   <img height="1" width="1" style="display:none" 
   src="https://www.facebook.com/tr?id=495888791243962&ev=PageView&noscript=1"/>`,
}

export const metaPixelDk = {
   metaPixelScript: `
   !function(f,b,e,v,n,t,s)
   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
   n.queue=[];t=b.createElement(e);t.async=!0;
   t.src=v;s=b.getElementsByTagName(e)[0];
   s.parentNode.insertBefore(t,s)}(window, document,'script',
   'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '1122579268866516');
   fbq('track', 'PageView')`,
   noScriptImgPixelElement: `
   <img height="1" width="1" style="display:none" 
   src="https://www.facebook.com/tr?id=1122579268866516&ev=PageView&noscript=1"/>`,
}

export const metaPixelNo = {
   metaPixelScript: `
   !function(f,b,e,v,n,t,s)
   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
   n.queue=[];t=b.createElement(e);t.async=!0;
   t.src=v;s=b.getElementsByTagName(e)[0];
   s.parentNode.insertBefore(t,s)}(window, document,'script',
   'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '7241949602536931');
   fbq('track', 'PageView')`,
   noScriptImgPixelElement: `
   <img height="1" width="1" style="display:none" 
   src="https://www.facebook.com/tr?id=7241949602536931&ev=PageView&noscript=1"/>`,
}
