import React, {useState, useRef} from 'react';
import { styled } from 'linaria/react'
import { cx } from 'linaria'
import { useQuery } from 'react-apollo'
import homeCategoriesQuery from './HomeCategoriesQuery.gql'
import MegaMenu from './MegaMenu';
import { debounce } from "lodash";
import { getBoolByPropertyName, getStringByPropertyName } from '../../Core/Util/Helpers';
import MenuSkeleton from '../../Core/Skeletons/MenuSkeleton';
import { useEffect } from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { useHeaderContext } from './HeaderContext/HeaderContext';

const MenuHeaderContainer = styled.div`
   .menu-row {
      background: #e9e9e9;
      font-family: var(--font-industry);
      font-weight: 700;
   }

   .nav {
      flex-wrap: nowrap;
      overflow: auto;
      position: relative;
   }

   .nav .nav-item {
      transition: margin 0.1s ease;
   }

   .scrolled-nav-items-margin {
      margin: 0.8rem 0px;
   }

   .nav .nav-link {
      color: #000;
      font-family: var(--font-industry);
      border-right: 2px solid rgba(0, 0, 0, 0.15);
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 0.5rem;
   }

   .nav .nav-item:last-child .nav-link {
      border-right: 0;
   }

   .nav-link:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease;
   }

   .nav .nav-item:first-child a {
      padding-left: 0 !important;
   }

   .nav-link:hover .link--vb::before {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
   }
`

const MenuHeader = ({categories, cmsCategories, featuredCategories}) => {
    const [megaMenuIsOpen, setMegaMenuIsOpen] = useState(false)
    const [activeCategory, setActiveCategory] = useState(null)
    const elementRef = useRef(null);
    const [hoverBreakPoint, setHoverBreakPoint] = useState(1000)
    const [menuCategories, setMenuCategories] = useState(null)
    const { isScrolled } = useHeaderContext()

    const debouncedHandleMouseEnter = debounce((category) => {
      handleMouseEnter(category)
    }, 300)

    const handleMouseEnter = (category) => {
        if(!category){
         return
        }
        const rect = elementRef.current.getBoundingClientRect();
        setHoverBreakPoint(rect?.top + rect?.height)
        setActiveCategory(category)
        setMegaMenuIsOpen(true)
    };
    
    const handleMouseLeave = (e) => {
        debouncedHandleMouseEnter()?.cancel();
        if(e.clientY > hoverBreakPoint-2)
        {
           return; 
        }
        setMegaMenuIsOpen(false);
    };
    
    const handleMouseMegaMenuLeave = () => {
        setMegaMenuIsOpen(false)
    };

   useEffect(() => {
      setMenuCategories(cmsCategories)
   }, [cmsCategories, setMenuCategories])

    if(!menuCategories) {
      return <MenuSkeleton></MenuSkeleton>
    }

    return (
       <>
          <MenuHeaderContainer>
             <div className="row m-0 p-0 d-none d-xl-block menu-container">
                <div className="col-12 menu-row px-128" id="menuHeader">
                   <ul className="nav">
                      {menuCategories?.map((cmsCat, i) => {
                         const {properties} = cmsCat;
                         const iconUrl = getStringByPropertyName(properties, 'icon')
                         const id = getStringByPropertyName(properties, 'categoryId')
                         const isActive = getStringByPropertyName(properties, 'isActive')
                         const category = categories?.find(c => c.id == id)

                         if(isActive == "hidden"){
                           return (<React.Fragment key={i}></React.Fragment>)
                         }

                         return (
                            <li
                               className={cx('nav-item', isScrolled ? 'scrolled-nav-items-margin' : 'my-32' )}
                               ref={elementRef}
                               key={i}
                               onMouseEnter={() => debouncedHandleMouseEnter(category)}
                               onMouseLeave={handleMouseLeave}
                            >
                               <CategoryLink
                                  className="nav-link active"
                                  category={category}
                                  onClick={() => setMegaMenuIsOpen(false)}
                               >
                                  {' '}
                                  {iconUrl && <><img src={iconUrl} alt={category?.name} />{' '}</>}
                                  <span className="link link--vb">
                                     {category?.name}
                                  </span>
                               </CategoryLink>
                            </li>
                         )
                      })}
                   </ul>
                </div>
             </div>
          </MenuHeaderContainer>
          {menuCategories?.map((cmsCat, i) => {
             const id = getStringByPropertyName(cmsCat?.properties, 'categoryId')
             const linkOnly = getBoolByPropertyName(cmsCat?.properties, 'linkOnly')
             const category = categories?.find(c => c.id == id)
             if(linkOnly){
               return (<React.Fragment key={i}></React.Fragment>)
             }
             return (
                <MegaMenu
                   key={i}
                   category={category}
                   featuredCategories={featuredCategories}
                   visible={megaMenuIsOpen && activeCategory?.id == category?.id}
                   onMouseLeaveEvent={handleMouseMegaMenuLeave}
                   setMegaMenuIsOpen={setMegaMenuIsOpen}
                ></MegaMenu>
             )
          })}
       </>
    )
};

export default MenuHeader;