import React, { useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import SiteButton from '../../Core/SiteButton'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import { NavArrowRight } from 'iconoir-react'
import FooterNewsletter from './FooterNewsletter'

const InspirationBlock = styled.div`
    position: relative;
    min-height: 12rem;
    background-size: cover !important;
    background-image: ${props => `url("${props.image}")`};
    z-index: 1;

    h2 {
        margin-bottom: 0.75rem;
    }

  &::after {
    content: " ";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`

const FooterInspiration = ({ newsLetter, inspirationBlocks }) => {
   return (
      <>
         <FooterNewsletter newsLetter={newsLetter}></FooterNewsletter>
    
         {inspirationBlocks?.map((inspBlock, i) => {
            const title = getStringByPropertyName(
               inspBlock?.properties,
               'title'
            )
            const image = getStringByPropertyName(
               inspBlock?.properties,
               'image'
            )
            const btnText = getStringByPropertyName(
               inspBlock?.properties,
               'ctaText'
            )
            const btnLink = getStringByPropertyName(
               inspBlock?.properties,
               'ctaLink'
            )
            return (
               <InspirationBlock
                  key={i}
                  className="col-12 col-lg-6 d-flex flex-column justify-content-end align-items-start p-32"
                  image={image}
               >
                  <h2>{title}</h2>
                  <SiteButton
                     className="py-8 px-16"
                     styleType="hollow"
                     color="white"
                     icon={true}
                     href={btnLink}
                  >
                     {btnText}
                     <NavArrowRight
                        strokeWidth={2.0}
                        style={{ marginLeft: '0.3rem' }}
                     ></NavArrowRight>
                  </SiteButton>
               </InspirationBlock>
            )
         })}
      </>
   )
}
 
export default FooterInspiration;